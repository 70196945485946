

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const Secondtext = styled.h2`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

export default function Cornerfaq() {
    return (
        <>
        <Separator />
       
        <Section py={4} id="faq" bg="#f7f7fb" className="pb-md-5 mb pt-md-5">
      <Container className="pb-md-5 mb pt-md-5 pt-3 pb-3">
        <Row className="justify-content-center pb-4">
          <Col lg="12">
   
            <Iwrap>


<Itext as="h2">Questions about <span>Corner Of Mouth</span></Itext>
</Iwrap>
      <Secondtext>
      Frequently Asked Questions

      </Secondtext>
         
          </Col>
        </Row>


        <Row className="">
       
       


        <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Accordion allowZeroExpanded >

<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
What does the treatment involve?        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        The treatment is carried out by injecting into the strategic points of the corner of the mouth to lift and soothe the lines/creases; this provides results based on the patient's requirements and suitability. Treatment typically lasts around 10 minutes.

        </p>
    </AccordionItemPanel>
</AccordionItem>



</Accordion>    
          </Col>


          <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Accordion allowZeroExpanded >

<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Does the treatment hurt?
       </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Numbing cream is applied to the area to be injected and only when it is numbed, the treatment is done, making your experience as comforting as possible. Based on all our previous patients, the pain was mild (small pinch).


        </p>
    </AccordionItemPanel>
</AccordionItem>



</Accordion>    
          </Col>

          <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
<Accordion allowZeroExpanded classname="">
  
  <AccordionItem>
      <AccordionItemHeading>
          <AccordionItemButton>
              1. What is Corner of Mouth (Marionette) Treatment?
          </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
          <p>
              Corner of Mouth Treatment, also known as Marionette Treatment, is a non-surgical cosmetic procedure aimed at addressing the appearance of marionette lines. These lines, which extend from the corners of the lips to the chin, often give the face a sad or aged look. The treatment involves strategically injecting dermal fillers to lift and rejuvenate the corners of the mouth, resulting in a younger and fresher appearance.
          </p> 
      </AccordionItemPanel>
  </AccordionItem>
  <br />

  <AccordionItem>
      <AccordionItemHeading>
          <AccordionItemButton>
              2. How does this treatment differ from a surgical procedure?
          </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
          <p>
              Unlike surgical procedures, Corner of Mouth Treatment is non-invasive and does not require incisions or general anesthesia. It involves the use of dermal fillers to achieve desired results without the need for surgery, making it a safer and more convenient option for many individuals.
          </p> 
      </AccordionItemPanel>
  </AccordionItem>
  <br />

  <AccordionItem>
      <AccordionItemHeading>
          <AccordionItemButton>
              3. What is non-surgical rhinoplasty?
          </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
          <p>
              Non-surgical rhinoplasty, also known as a non-surgical nose job, is a cosmetic procedure that involves the use of injectable fillers to reshape and enhance the appearance of the nose without surgery. It can be an effective solution for addressing minor imperfections or asymmetries in the nose, providing immediate results with minimal downtime.
          </p> 
      </AccordionItemPanel>
  </AccordionItem>
  <br />

  <AccordionItem>
      <AccordionItemHeading>
          <AccordionItemButton>
              4. How long do the results of Corner of Mouth Treatment last?
          </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
          <p>
              The results of Corner of Mouth Treatment typically last between 12 to 18 months. However, individual results may vary depending on factors such as skin type, lifestyle, and the specific type of filler used. Regular maintenance treatments may be recommended to maintain optimal results over time.
          </p> 
      </AccordionItemPanel>
  </AccordionItem>
  <br />

  <AccordionItem>
      <AccordionItemHeading>
          <AccordionItemButton>
              5. What is the cost of Corner of Mouth Treatment?
          </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
          <p>
              The cost of Corner of Mouth Treatment starts from £300. However, the total cost may vary depending on factors such as the amount of filler required, the expertise of the practitioner, and any additional treatments or services included in the package. A personalized consultation can provide a more accurate cost estimate based on individual needs.
          </p> 
      </AccordionItemPanel>
  </AccordionItem>
  <br />

  <AccordionItem>
      <AccordionItemHeading>
          <AccordionItemButton>
              6. How long does the procedure take?
          </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
          <p>
              The Corner of Mouth Treatment procedure typically takes around 10 minutes to complete. This quick and efficient process allows patients to undergo treatment during their lunch break or between errands, with minimal disruption to their daily schedule.
          </p> 
      </AccordionItemPanel>
  </AccordionItem>
  <br />

  <AccordionItem>
      <AccordionItemHeading>
          <AccordionItemButton>
              7. Is there any downtime after the treatment?
          </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
          <p>
              No, there is minimal to no downtime after Corner of Mouth Treatment. Patients can resume their normal daily activities immediately after the procedure, including work, exercise, and socializing. However, it is important to follow post-treatment care instructions provided by the practitioner to ensure optimal results and minimize any potential side effects.
          </p> 
      </AccordionItemPanel>
  </AccordionItem>
  <br />

  <AccordionItem>
      <AccordionItemHeading>
          <AccordionItemButton>
              8. Does Corner of Mouth Treatment involve any pain?
          </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
          <p>
              Most patients experience minimal discomfort during Corner of Mouth Treatment, with some reporting no pain at all. The dermal fillers used typically contain a local anesthetic to enhance comfort during the injection process. Any discomfort experienced is usually mild and temporary, subsiding shortly after the procedure.
          </p> 
      </AccordionItemPanel>
  </AccordionItem>
  <br />

  <AccordionItem>
      <AccordionItemHeading>
          <AccordionItemButton>
              9. How soon can I expect to see results?
          </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
          <p>
              Results from Corner of Mouth Treatment are visible immediately after the procedure. Patients can enjoy a noticeable improvement in the appearance of marionette lines and a lifted, rejuvenated look to the corners of the mouth right away. These instant results can boost confidence and enhance overall facial aesthetics.
          </p> 
      </AccordionItemPanel>
  </AccordionItem>
  <br />

  <AccordionItem>
      <AccordionItemHeading>
          <AccordionItemButton>
              10. Are the treatments customized to individual needs?
          </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
          <p>
              Yes, all Corner of Mouth Treatments are tailored to the individual needs and preferences of each patient. Prior to the procedure, a comprehensive consultation is conducted to understand the patient's goals, assess their facial anatomy, and determine the most suitable treatment approach. This personalized approach ensures that each patient receives optimal results that enhance their natural features and achieve their desired aesthetic outcomes.
          </p> 
      </AccordionItemPanel>
  </AccordionItem>
</Accordion>

</ Col>


        



        </Row>



<br /> <br />
              <a href 
                                            ="https://connect.pabau.com/bookings.php?compid=8607" 
                                            activeClassName="active"
                                           
                                          
                                        >      <Bookbutton>
                
                
                Book Appointment
               
                </Bookbutton> </a>
        


       


        </Container>
        </Section>
        <SeparatorEnd />
        </>

 


    );
}







